import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import useTranslation from 'shared/hooks/useTranslation';
import useMediaQuery from 'shared/hooks/useMediaQuery';

const MobileSection = dynamic(() => import('modules/home/v2/MainSectionVideo/mobile'));
const DesktopSection = dynamic(() => import('modules/home/v2/MainSectionVideo/desktop'), {
  ssr: false,
});

const Section = styled.div`
  @media (min-width: 1170px) {
    min-height: 100vh;
  }
`;

const Mobile = styled.div`
  ${({ lang }) =>
    (lang === 'pl' || lang === 'en' || lang === 'sk' || lang === 'cs') &&
    css`
      @media (min-width: 1170px) {
        display: none !important;
      }
    `}
`;

const Desktop = styled.div`
  @media (max-width: 1170px) {
    display: none !important;
  }
`;

function MainSectionVideo(): JSX.Element {
  const { lang } = useTranslation('');

  const { isMatch: isDesktop, mediaLoaded } = useMediaQuery('(min-width: 1170px)');

  return (
    <Section>
      <Mobile lang={lang}>
        <MobileSection />
      </Mobile>
      {isDesktop && mediaLoaded && (lang === 'pl' || lang === 'en' || lang === 'sk' || lang === 'cs') ? (
        <Desktop>
          <DesktopSection />
        </Desktop>
      ) : null}
    </Section>
  );
}

export default MainSectionVideo;
