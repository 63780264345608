import styled, { css } from 'styled-components';

type HeaderProps = {
  isColored?: boolean;
};

export const sectionStyles = css`
  max-width: 180rem;
  margin: 0 auto;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  padding: 0rem 0rem;

  @media ${({ theme }) => theme.DEVICE.MD} {
    margin: 3.2rem auto;
    padding-bottom: 0;
    padding: 3.2rem 1.6rem;
  }
`;

export const Section = styled.section`
  ${sectionStyles}
`;

const headerStyles = css<HeaderProps>`
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 107%;
  text-align: center;

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 7.8rem;
    line-height: 8.3rem;
  }
`;

export const StyledH1 = styled.h1<HeaderProps>`
  ${headerStyles};
  color: ${({ theme, isColored }) => (isColored ? theme.COLORS.V2.SECONDARY : theme.COLORS.V2.PRIMARY)};

  span {
    color: ${({ theme }) => theme.COLORS.V2.SECONDARY};
  }
`;

export const StyledH2 = styled.h2<HeaderProps>`
  color: ${({ theme, isColored }) => (isColored ? theme.COLORS.V2.SECONDARY : theme.COLORS.V2.PRIMARY)};
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.9rem;
  text-align: center;
  margin-top: 0.8rem;

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 5.3rem;
    font-weight: 700;
    line-height: 6rem;
  }
`;

export const StyledH3 = styled.h3<HeaderProps>`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3.2rem;
  text-align: center;
  color: ${({ theme, isColored }) => (isColored ? theme.COLORS.V2.SECONDARY : theme.COLORS.V2.PRIMARY)};

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3.2rem;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.COLORS.V2.PRIMARY};
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 142%;
  text-align: center;

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.5rem;
    margin-top: 2rem;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.6rem 1.2rem;
  text-align: center;

  @media ${({ theme }) => theme.DEVICE.MD} {
    padding: 2.4rem 0;
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  max-width: 160rem;
  margin: 0 auto;
  overflow: hidden;
`;
